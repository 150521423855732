.App {
  font-family: "Roboto", sans-serif;
}
.App:lang(bn) {
  font-family: "Noto Sans Bengali", sans-serif;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
header {
  overflow: hidden;
}
.App-link {
  color: #61dafb;
}

.cv_maker_h1 {
  color: #0d7ade;
  font-family: "Noto Sans Bengali";
  font-size: 25px;
  font-weight: 400;
  margin-top: 45px;
}

.cv_maker_form_group {
  margin-bottom: 20px;
}

.cv_maker_card {
  background-color: #fafbff;
  border: 0;
}

.sign_up_phone_box {
  background: #ffffff;
  border: 1px solid #ccd2db;
  margin-left: 5px;
  border-radius: 6px;
  padding: 10px;
}

.sign_up_phone_box2 {
  margin: 0px 0px 30px 0px;
}

.otp-field {
  width: 70px;
  height: 70px;
  text-align: center;
  font-size: 24px;
  margin: 30px 0px 10px 0px;
  border: 1px solid #5f5f5f;
  border-radius: 4px;
}

.cv_maker_name {
  font-size: 22px;
  margin: 5px 5px 5px 10px;
}

.cv_maker_contact_details {
  font-size: 12px;
  margin: 5px 5px 5px 5px;
  white-space: pre-wrap;
}

.cv_maker_sections_key {
  font-size: 12px;
  margin: 5px 10px 5px 2px;
  color: #353535;
}

.cv_maker_sections_value {
  font-size: 12px;
  margin: 5px 0 5px 2px;
  color: #616161;
}

.cv_maker_sections_date {
  font-size: 12px;
  margin: 5px 10px 5px 2px;
  color: #616161;
}

.cv_maker_line_headers {
  font-size: 13px;
  margin: 0 25px 0 0;
  text-decoration: underline;
  line-height: 2;
  text-decoration-color: #8cc547;
}

.cv_maker_header {
  background: #d2e2bf;
}

.cv_maker_bigger {
  font-size: 15px;
  font-style: italic;
}

.selected_card {
  border: 2px solid red;
}

.activeClicked {
  background-color: #87c33f;
}
.sidebar_selected_item {
  background-color: #87c33f;
  color: #ffffff !important;
  border-radius: 48px;
  /* max-width: 185px; */
  max-height: 41px;
  font-family: "Noto Sans Bengali", sans-serif;
  font-style: normal;
  align-items: center;
  align-content: center;
  /* font-size: 16px; */
}
.sidebar_item_font {
  color: #000000 !important;
  font-family: "Noto Sans Bengali", sans-serif !important;
  font-style: normal !important;
  /* font-size: 14px !important; */
}

.sidebar_item_font_selected {
  color: #ffffff !important;
  font-family: "Noto Sans Bengali", sans-serif !important;
  font-style: normal !important;
  /* font-size: 14px !important; */
}

@media only screen and (max-width: 768px) {
  img {
    max-height: 120px;
  }
}

.profile_form_label {
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.profile_form_title {
  color: #5f5f5f;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.input-with-icon {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tt {
  position: absolute;
  top: calc(-100% - 20px);
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 1;
  min-width: 200px;
}

.white_box {
  background-color: #fff;
  border-radius: 9px;
}

.company_info_box {
  padding: 55px 92px;
}

.company_name_logo {
  display: flex;
  margin-bottom: 45px;
}

.company_logo img {
  height: 53px;
  width: auto;
  display: block;
}

.company_name_location {
  margin-left: 25px;
  line-height: 1;
}

.company_name p {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: #242e39;
  letter-spacing: 1px;
}
.company_name h1 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 800;
  color: #242e39;
  letter-spacing: 1px;
}

.company_location {
  margin-top: 14px;
}

.company_location p {
  font-size: 16px;
  color: #60758a;
  transition: all 0.2s linear;
}

.company_details_wrapper {
  margin-top: 35px;
  margin-bottom: 25px;
}

.company_details_wrapper a {
  color: #242e39;
  transition: all 0.2s linear;
}

.company_details_wrapper a:hover {
  color: #ff757b;
}

.company_details_wrapper .company_social {
  margin-top: 10px;
}

.company_details_wrapper .company_social a {
  margin-right: 14px;
}

.company_details_wrapper .company_social a i {
  font-size: 20px;
  color: #242e39;
  background-color: #f1f6fb;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  text-align: center;
  padding-top: 10px;
  transition: all 0.2s linear;
}

.company_details_wrapper .company_social a i:hover {
  background-color: #ff757b;
  color: #fff;
}

.company_about {
  margin-top: 60px;
}

.company_about h1 {
  font-family: "Poppins", sans-serif;
  font-size: 25px;
  font-weight: 600;
  color: #242e39;
}

.apply_head {
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #242e39;
}

.company_about p {
  margin-top: 30px;
}

.company_img_box {
  height: 245px;
  margin-top: 40px;
}

.company_img_box img {
  border-radius: 8px;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
}
.font_light {
  color: #60758a !important;
}
.section_title {
  font-family: Noto Sans Bengali;
  font-size: 35px;
  font-weight: 800;
  color: #242e39;
  text-transform: capitalize;
}

.outline_btn {
  line-height: 1;
  font-size: 18px;
  padding: 10px 15px;
  border: 1px solid #03438c;
  border-radius: 4px;
  color: #03438c;
  background-color: #fff;
  text-transform: capitalize;
  transition: all 0.2s linear;
}

.outline_btn:hover {
  background-color: #ff757b;
  border-color: #ff757b;
  color: #fff;
}

.job_cart {
  padding: 24px 25px;
  background-color: #f4f2f6;
  border-radius: 15px;
  margin-bottom: 25px;
  transition: all 0.2s linear;
  box-shadow: 0px 2.7531px 2.7531px 0px rgba(0, 0, 0, 0.25);
}

.job_cart:hover {
  box-shadow: 0 5px 10px 0 rgba(3, 67, 140, 0.39);
}

.jc_top_part {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.jc_logo img {
  height: 53px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  display: block;
}

.jc_save i {
  font-size: 25px;
  color: #3b444d;
  cursor: pointer;
  line-height: 1;
}

.jc_company_name {
  margin-top: 9px;
}

.jc_company_name a {
  font-size: 16px;
  color: #1e1e1e;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: all 0.2s linear;
}

.jc_company_name span {
  font-size: 16px;
  color: #1e1e1e;
  font-style: normal;
  line-height: normal;
  transition: all 0.2s linear;
}

.jc_company_name a:hover {
  color: #e9be20;
}
.jc_job_title {
  margin-top: 9px;
}

.jc_job_title a {
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  color: #1e1e1e;
  letter-spacing: 1px;
  transition: all 0.1s linear;
}

.jc_job_title a:hover {
  color: #e9be20;
}

.jc_info {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-bottom: 41px;
}

.jc_info .jc_info_box {
  display: flex;
  align-self: start;
  font-size: 15px;
  padding: 7px 8px;
  border-radius: 4px;
}

.jc_info i {
  margin-right: 8px;
}

.jc_info .jc_location {
  background-color: #dfeaff;
  color: rgba(3, 67, 140, 0.62);
}

.jc_info .jc_type {
  background-color: #ffe4e6;
  color: #e9be20;
}

.jc_info .jc_amount {
  background-color: rgba(51, 148, 77, 0.12);
  color: #32944c;
}

.jc_bottom_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.share_icon {
  font-size: 23px;
  color: #242e39;
  transition: all 0.2s linear;
}

.share_icon:hover {
  color: #e9be20;
}

.similar_job .job_cart {
  background-color: #fff;
}
.jc_details_btn {
  background-color: #87c33f;
  color: #f4f2f6;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border-radius: 68px;
  padding: 10px 10px 10px;
  transition: all 0.2s linear;
}

.jc_details_btn:hover {
  background-color: #e9be20;
  color: #000;
}

.blue_btn {
  line-height: 1;
  background-color: #03438c;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  padding: 10px 10px 10px;
  transition: all 0.2s linear;
}
.blue_btn2 {
  line-height: 1;
  background-color: #03438c;
  color: #fff;
  font-size: 16px;
  border-radius: 4px;
  padding: 10px 10px 10px;
  transition: all 0.2s linear;
}

.blue_btn:hover {
  background-color: #ff757b;
  color: #fff;
}
.blue_btn2:hover {
  background-color: #ff757b;
  color: #fff;
}

.section_top {
  margin-bottom: 57px;
}
.company_job_opening .job_cart {
  background-color: #fff;
}

.not_found_h2 {
  color: #010101;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.not_found_p {
  color: #010101;
  text-align: center;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.form_wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.form_wrapper input select {
  height: 40px;
  border: 1px solid rgba(96, 117, 138, 0.25);
  border-radius: 7px;
  padding: 0 15px;
}

.sidebar_header_text {
  color: #1e1e1e;
  /* font-family: Noto Sans Bengali UI; */
  font-family: "Noto Sans Bengali", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 19.75px */
  letter-spacing: -0.29px;
  white-space: wrap;
  cursor: pointer;
}
.success-story-section {
  padding: 15px;
  margin-top: 20px;
}
.success-story-section h1 {
  color: #000;
  /* font-family: Noto Sans Bengali; */
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

.success_story_card1 {
  border-radius: 5px;
  border: 0.2px solid #000;
  background: #f3f3f3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.success_story_card1 h2 {
  color: #000;
  text-align: center;
  font-family: Noto Sans Bengali;
  font-size: 19.22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.success_story_card2 {
  border-radius: 5px;
  border: 0.225px solid #000;
  background: #f3f3f3;
  box-shadow: 0px 4.5px 4.5px 0px rgba(0, 0, 0, 0.25);
}

.success_story_card2 h2 {
  color: #000;
  text-align: center;
  font-family: Noto Sans Bengali;
  font-size: 21.623px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.success_story_card2 p {
  color: #000;
  font-family: Noto Sans Bengali;
  font-size: 21.623px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  max-height: 300px;
  overflow: auto;
  position: relative;
}

.trainings_h2 {
  color: #0a0909;
  text-align: start;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans Bengali;

  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.463px;
}
.trainings_h22 {
  color: #6d6d6d;
  text-align: end;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans Bengali;

  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.161px;
}

.scroll-container {
  overflow-y: auto;
  white-space: nowrap;
}

.scroll-container::-webkit-scrollbar {
  display: none;
}

.scroll-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.App::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

.saved_jobs h1 {
  color: #000;
  font-family: Noto Sans Bengali;
  font-size: 29.174px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.applied_job_top_card,
.applied_job_top_card_sc {
  justify-content: center;
  align-items: center;
  padding: 24px 25px;
  margin-bottom: 25px;
  transition: all 0.2s linear;
  border-radius: 12px;
  border: 1px solid #000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.applied_job_top_card:hover {
  box-shadow: 0 5px 10px 0 rgba(3, 67, 140, 0.39);
}
.applied_job_top_card {
  background: #fff;
}

.applied_job_top_card_sc {
  background: #87c33f;
}

.applied_job_top_card p,
.applied_job_top_card_sc p {
  color: #252323;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali", sans-serif;
  font-size: 19px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.065px;
  letter-spacing: -0.439px;
}

/* Slider CSS Start*/

.slick-slide {
  height: 337px;
  max-height: 400px;
  width: 100%;
}
.slick-slide img {
  max-height: 400px;
  cursor: pointer;
}

.custom-slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}
.custom-slick-dots li {
  position: relative;

  display: inline-block;

  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;

  cursor: pointer;
}
.custom-slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.custom-slick-dots li button:hover,
.custom-slick-dots li button:focus {
  outline: none;
}
.custom-slick-dots li button:hover:before,
.custom-slick-dots li button:focus:before {
  opacity: 1;
}
.custom-slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: "•";
  text-align: center;

  opacity: 0.25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

/* Slider CSS End*/

.job_filter button {
  width: 193px;
  align-items: center;
  height: 47px;
  border-radius: 98px;
  margin-right: 10px;
}

.success_btn {
  background-color: #87c33f;
  border-color: transparent;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans Bengali;

  font-style: normal;
  font-weight: 500;
  line-height: 19.605px; /* 90.909% */
  letter-spacing: -0.49px;
}

.outline_btn {
  background-color: #fff;
  border-color: #87c33f;
  color: #4e4e4e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans Bengali;

  font-style: normal;
  font-weight: 400;
  line-height: 23.849px; /* 110.588% */
  letter-spacing: -0.596px;
}

.job_filter button:hover {
  background-color: #e9be20;
  border-color: transparent;
  color: #000;
}

.job_filter input {
  height: 47px;
  /* border: 1px solid rgba(96,117,138,.25); */
  border-radius: 7px;
  color: #1f1f1f;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali", sans-serif;
  font-size: 17.804px;
  font-style: normal;
  font-weight: 500;

  letter-spacing: -0.469px;
}

.job_filter2 {
  border-radius: 7px;
  color: #1f1f1f;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17.804px;
  font-style: normal;
  font-weight: 500;
  font-family: "Noto Sans Bengali", sans-serif;
  letter-spacing: -0.469px;
}
.input_field_icon {
  width: 21.552px;
  height: 21.552px;
  flex-shrink: 0;
}

.jd_section {
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 4.04938px 4.04938px 0px rgba(0, 0, 0, 0.25);
  padding: 43px 57px;
  margin-top: 20px;
}

.jd_section .top {
  display: flex;
  justify-content: center;
  color: #1e1e1e;
}

.jd_section .top h1 {
  margin-top: 15px;
  font-size: 32.297px;
  font-style: normal;
  font-weight: 700;
  white-space: normal;
  text-align: center;
}
.jd_section .top a,
h2 {
  font-size: 28.984px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
  color: #1e1e1e;
  text-align: center;
}

.jd_section .top a:hover {
  color: #e9be20;
}

.jd_section .basic p {
  color: #000;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
}
.jd_section p {
  color: #000;
  font-style: normal;
  /* font-weight: 700; */
  line-height: normal;
  white-space: normal;
}
.jd_section .basic .p_link {
  cursor: pointer;
}

.jd_section .basic .p_link:hover {
  color: #e9be20;
}

.wr {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Noto Sans Bengali;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.apply_btn {
  background-color: #87c33f;
  color: #f4f2f6;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border-radius: 50px;
  padding: 10px 30px;
  transition: all 0.2s linear;
  cursor: pointer;
}

.apply_btn:hover {
  background-color: #e9be20;
  color: #000;
}
.apply-confirm-btn {
  background-color: #87c33f;
  color: #f4f2f6;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 19px;
  padding: 10px 15px;
  margin: 0px 5px;
  transition: all 0.2s linear;
  cursor: pointer;
  text-align: center;
}

.apply-confirm-btn:hover {
  background-color: #e9be20;
  color: #000;
}
.apply-confirm-btn-2 {
  background-color: #fff;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 19px;
  padding: 10px 15px;
  margin: 0px 5px;
  transition: all 0.2s linear;
  cursor: pointer;
  border-color: #87c33f;
  border-style: solid;
  text-align: center;
  border-width: 1px;
}

.apply-confirm-btn-2:hover {
  background-color: #e9be20;
  color: #000;
  border-color: #e9be20;
}
.apply-confirm-title {
  color: #434343;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.5px;
}
.apply-confirm-p {
  color: #6b6666;
  text-align: center;
  font-family: "Noto Sans Bengali";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.9%; /* 18.746px */
}
.apply-confirm-alert {
  width: 400px;
  flex-shrink: 0;
  border-radius: 11px;
  background: rgba(255, 0, 0, 0.1);
  margin: 20px 0px;
}
.apply-confirm-alert p {
  margin: 0px 12px 0px 8px;
  text-align: justify;
}
.apply-confirm-alert .span1 {
  color: #6d6d6d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}

.apply-confirm-alert .span2 {
  color: #6d6d6d;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.input_field_p {
  color: #000;
  font-family: Noto Sans Bengali;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: normal;
}

.company_profile_title {
  color: var(--neutrals-100, #25324b);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
}

.company_card {
  padding: 24px 25px;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 25px;
  transition: all 0.2s linear;
  box-shadow: 0px 2.7531px 2.7531px 0px rgba(0, 0, 0, 0.25);
}

.company_card:hover {
  box-shadow: 0 5px 10px 0 rgba(3, 67, 140, 0.39);
}
.company_card h1 {
  color: #1e1e1e;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
  margin-top: 12px;
}

.company_card p {
  color: #1e1e1e;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.all_company_header h1 {
  color: var(--neutrals-100, #25324b);
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.all_company_header p {
  color: var(--neutrals-60, #7c8493);
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.company_filter_section {
  display: flex;
  padding: 24px;

  gap: 20px;
  border-radius: 6px;
  background: var(--color-white, #fff);
  box-shadow: 0px 2.71357px 4.39666px 0px rgba(192, 192, 192, 0.03),
    0px 6.86281px 11.11949px 0px rgba(192, 192, 192, 0.04),
    0px 13.99949px 22.68272px 0px rgba(192, 192, 192, 0.05),
    0px 28.8363px 46.72211px 0px rgba(192, 192, 192, 0.06),
    0px 79px 128px 0px rgba(192, 192, 192, 0.09);
}

.divider-container {
  max-width: 1px;
  height: 47px;
}

.sidebar-responsive {
  display: block;
}
.header-responsive {
  display: none !important;
}
.app-banner-big-screen {
  display: block;
}

.app-banner-small-screen {
  display: none;
}

.profile_section h1 {
  color: #1e1e1e;
  text-align: start;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
  font-family: Noto Sans Bengali;
}

.profile_section h2 {
  color: #1e1e1e;
  text-align: start;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: normal;
  font-family: Noto Sans Bengali;
}

.MuiPickersStaticWrapper-root .MuiPickersBasePicker-root {
  height: 300px; /* Set your desired fixed height here */
}

.CardTwo_Wrapper__DgV5H {
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
}

.CardTwo_Content__ywwwN {
  padding: 24px 32px 32px;
}

.CardTwo_Title__MmDvL {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2.3125rem;
  display: flex;
  align-items: center;
  text-align: start;
  letter-spacing: -0.02em;
  color: #212121;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(2.3125rem * 3);
}

.CardTwo_Desc__Pm042 {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.8;
  color: #616161;
  margin-bottom: 18px;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.8 * 3);
}

.CardTwo_Link__NEjYk {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #212121;
  display: flex;
  gap: 12px;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
.CardTwo_Link__NEjYk svg {
  fill: #212121;
  transition: fill 0.3s ease;
}

.CardTwo_Link__NEjYk:hover {
  color: #e9be20;
}
.CardTwo_Link__NEjYk:hover svg {
  fill: #e9be20;
}

/* Default styles */
.successfully-get-job-section {
  display: block;
}
.successfully-get-job-section-1600 {
  display: none;
}

.successfully-get-job-section-1200 {
  display: none;
}

.successfully-get-job-section-992 {
  display: none;
}

.successfully-get-job-section-768 {
  display: none;
}

.successfully-get-job-section-480 {
  display: none;
}
.successfully-get-job-card {
  padding: 24px 25px;
  height: 400px;
  background-color: #fff;
  margin-bottom: 25px;
  transition: all 0.2s linear;
}
.successfully-get-job-card-p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #616161;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5rem * 2);
}

.successfully-get-job-card-h {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.9rem * 2);
}
.successfully-get-job-card-mid {
  height: calc((1.9rem * 2) + (1.5rem * 2));
}

.company-card-p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: #616161;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.5rem * 2);
}

.company-card-h {
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.9rem;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: #212121;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.9rem * 2);
}
.company-mid {
  height: calc((1.9rem * 2) + (1.5rem * 2));
}

.signup-card {
  padding: 35px 35px;

  background-color: #fff;
  margin-bottom: 25px;
  transition: all 0.2s linear;
}
.signup-card-title h2 {
  font-weight: 700;
  font-size: 1.1rem;
  line-height: 2rem;
  display: flex;
  align-items: start;
  text-align: start;
  letter-spacing: -0.02em;
  color: #212121;
  white-space: wrap;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: calc(1.9rem * 2); */
}
.send-otp-btn {
  background-color: #87c33f;
  color: #f4f2f6;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  border-radius: 68px;
  padding: 10px 10px;
  transition: all 0.2s linear;
  cursor: pointer;
}
.send-otp-btn-disable {
  background-color: #e9e9e9;
  color: #4b4b4b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  line-height: normal;
  border-radius: 68px;
  padding: 10px 10px;
  transition: all 0.2s linear;
}
.feedback .p1 {
  color: #323e49;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
}
.feedback .p2 {
  color: #323e49;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 125% */
  letter-spacing: -0.5px;
}
.feedback .rating {
  cursor: pointer;
}
.feedback textarea {
  width: 350px;
  min-height: 127px;
  max-height: 200px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #87c33f;
  margin-bottom: 34px;
  color: #292936;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.5px;
}
.custom-scrollbar-textarea::-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar-textarea::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
  padding: 5px;
}

.custom-scrollbar-textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.feedback-textarea {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease;
}

.feedback-textarea.open {
  max-height: 500px;
}
.feedback .thank-you-title {
  color: #323e49;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: -0.5px;
}

.feedback .thank-you-details {
  color: #777;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Noto Sans Bengali";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 135% */
  letter-spacing: -0.5px;
}
.rating-form {
  transition: opacity 0.5s ease-in-out;
}

.rating-form.hidden {
  opacity: 0;
}

@media (max-width: 1500px) {
  .successfully-get-job-section {
    display: none;
  }

  .successfully-get-job-section-1600 {
    display: block;
  }
  .successfully-get-job-section-1200,
  .successfully-get-job-section-992,
  .successfully-get-job-section-768,
  .successfully-get-job-section-480 {
    display: none;
  }
}
@media (max-width: 1200px) {
  .successfully-get-job-section,
  .successfully-get-job-section-1600 {
    display: none;
  }

  .successfully-get-job-section-1200 {
    display: block;
  }

  .successfully-get-job-section-992,
  .successfully-get-job-section-768,
  .successfully-get-job-section-480 {
    display: none;
  }
}

@media (max-width: 992px) {
  .successfully-get-job-section,
  .successfully-get-job-section-1600 {
    display: none;
  }

  .successfully-get-job-section-1200,
  .successfully-get-job-section-768,
  .successfully-get-job-section-480 {
    display: none;
  }

  .successfully-get-job-section-992 {
    display: block;
  }
}

@media (max-width: 768px) {
  .successfully-get-job-section,
  .successfully-get-job-section-1600,
  .successfully-get-job-section-1200,
  .successfully-get-job-section-992 {
    display: none;
  }

  .successfully-get-job-section-768 {
    display: block;
  }

  .successfully-get-job-section-480 {
    display: none;
  }
}

@media (max-width: 480px) {
  .successfully-get-job-section,
  .successfully-get-job-section-1600,
  .successfully-get-job-section-1200,
  .successfully-get-job-section-992,
  .successfully-get-job-section-768 {
    display: none;
  }

  .successfully-get-job-section-480 {
    display: block;
  }
}

@media (max-width: 760px) {
  .apply-confirm-alert {
    width: 350px;
  }
  .app-banner-big-screen {
    display: none;
  }

  .app-banner-small-screen {
    display: block;
  }
  .divider-container {
    display: none;
  }
  .header-responsive {
    display: block !important;
    overflow-y: auto;
  }
  .sidebar-responsive {
    display: none !important;
  }

  .MuiDivider-root {
    display: none;
  }
  .success-story-section {
    padding: 10px;
    margin-top: 10px;
  }
  .success-story-section h1 {
    font-size: 25px;
    margin-bottom: 12px;
  }

  .CardTwo_Desc__Pm042 {
    font-size: 0.98rem;
    line-height: 1.5;
    margin-bottom: 16px;
    max-height: calc(1.5 * 3);
  }
  .CardTwo_Title__MmDvL {
    font-size: 1.2rem;
    line-height: 1.9rem;
    letter-spacing: -0.02em;
    max-height: calc(1.9rem * 3);
  }
  .CardTwo_Link__NEjYk {
    font-size: 0.95rem;
    line-height: 1.25rem;
  }
  .section_title {
    font-family: Noto Sans Bengali;
    font-size: 25px;
    font-weight: 800;
    color: #242e39;
    text-transform: capitalize;
  }
  .jc_job_title a {
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    color: #1e1e1e;
    letter-spacing: 1px;
    transition: all 0.1s linear;
  }
  .jc_company_name a {
    font-size: 14px;
    color: #1e1e1e;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: all 0.2s linear;
  }
  .jc_company_name span {
    font-size: 12px;
    color: #1e1e1e;
    font-style: normal;
    line-height: normal;
    transition: all 0.2s linear;
  }
  .send-otp-btn {
    font-size: 14px;
    font-weight: 600;
  }
  .send-otp-btn-disable {
    font-size: 14px;
    font-weight: 600;
  }
}
